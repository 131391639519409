import React, {useEffect, useState} from "react";
import { Image, Link, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import { DefaultProps, PropTypes } from "../../../../core/Utils/SitecoreFields";

const SocHeroModal = ({ fields }) => {
  const [bgImg, setBgImg] = useState(fields["Background image mobile"]?.value.src);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setBgImg(fields["Background image"]?.value.src);
    }

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setBgImg(fields["Background image"]?.value.src);
      } else {
        setBgImg(fields["Background image mobile"]?.value.src);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`component soc-hero-modal d-flex flex-column justify-content-center ${fields["css classes"]?.value}`}
      style={{backgroundImage: `url(${bgImg})`}}
    >
      <div className="g-0">
        <div>
          <div className="hero-modal-popup mx-auto d-flex flex-column justify-content-between">
            <div className="hero-modal-content  mx-auto text-center">
              {fields["Logo image"]?.value instanceof Object ? (
                <Image
                  field={fields["Logo image"]?.value}
                  className="hero-modal-logo"
                />
              ) : (
                <div
                  className="hero-modal-logo mx-auto"
                  dangerouslySetInnerHTML={{
                    __html: fields["Logo image"]?.value,
                  }}
                />
              )}
              <h1>{fields.Title?.value}</h1>
              <p className="w-auto info-message">
                <Text field={fields.Subline} />
              </p>
            </div>
            <div className="border-top border-1 hero-modal-button">
              <div className="d-flex justify-content-between text-center">
                <a>
                  <p>{fields["Button 1 text"]?.value}</p>
                </a>
                <a
                  className="border-start border-1 enable"
                  onClick={() => {
                    window.scrollTo(window.innerHeight, window.innerHeight);
                  }}
                >
                  <p>{fields["Button 2 text"]?.value}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// SocHeroModal.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ContentBlock);
// SocHeroModal.defaultProps = DefaultProps.SitecoreItem.inject(
//   DefaultProps.ContentBlock,
// );

export default SocHeroModal;
